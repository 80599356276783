import { Grid, Tab, Tabs } from '@mui/material';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import styled from 'styled-components';
import { grayScale } from 'theme/variants';

const TabNavigationComponent = styled.div`
  border-bottom: 1px solid ${grayScale[50]};
`;

export function TabNavigation({
  tabs,
  currentTab,
  setCurrentTab,
}: {
  tabs: {
    id: string;
    icon: ReactElement;
    label: string;
  }[];
  currentTab: string | null;
  setCurrentTab: Dispatch<SetStateAction<string | null>>;
}) {
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <TabNavigationComponent>
      <Grid container display='flex' spacing={2}>
        <Tabs onChange={handleTabChange} value={currentTab}>
          {tabs.map((tab) => (
            <Tab
              // icon={tab.icon}
              iconPosition='start'
              key={`tab-${tab.id}`}
              label={tab.label}
              value={tab.id}
            />
          ))}
        </Tabs>
      </Grid>
    </TabNavigationComponent>
  );
}
