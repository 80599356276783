import { Button, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';

export function UpdateAvailable() {
  const [open, setOpen] = useState(false);

  const handleClose = async () => {
    setOpen(false);
    if ('caches' in window) {
      await caches.keys().then((cacheNames) => {
        // Iterate through the cache names and delete each one
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
      window.location.reload();
    }
  };

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (event.data === 'update_available') {
        setOpen(true);
      }
    };

    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, []);

  const action = (
    <Button color='secondary' onClick={handleClose} size='small'>
      REFRESH
    </Button>
  );

  return (
    <Snackbar
      action={action}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={6000}
      message='ONE UPDATE IS AVAILABLE!'
      open={open}
    />
  );
}
