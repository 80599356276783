import { Alert } from '@mui/material';
import { useDataTableItemContext } from 'components/contexts/DataTableItemContext';
import { Helmet } from 'react-helmet-async';
import { Applicant } from '../atoms/DataTable/Applicant';
import { DateTime } from '../atoms/DataTable/DateTime';
import { Operator } from '../atoms/DataTable/Operator';
import { Status } from '../atoms/DataTable/Status';
import { useFamilies } from '../hooks/useFamilies';
import { Header } from '../molecules/Header';
import { DataView } from '../organisms/DataView';

export function ApplicantColumn() {
  const { item } = useDataTableItemContext();

  const { hasApplicant } = item;

  return (
    <>
      {hasApplicant ? (
        <Alert severity='success'>Has Applicant</Alert>
      ) : (
        <Alert severity='error'>No Applicant</Alert>
      )}
    </>
  );
}

const headCells = [
  {
    id: 'id',
    disablePadding: true,
    label: 'Reference',
  },
  {
    id: 'familyStatus',
    disablePadding: true,
    label: 'Status',
    component: <Status keyName='familyStatus' />,
  },
  {
    id: 'familyName',
    disablePadding: true,
    label: 'Family Name',
  },
  {
    id: 'personalInformation',
    disablePadding: true,
    label: 'Applicant',
    component: <Applicant />,
  },
  {
    id: 'createdAt',
    disablePadding: true,
    label: 'Submitted Date',
    component: <DateTime keyName='createdAt' />,
  },
  {
    id: 'operator',
    disablePadding: true,
    label: 'Operator',
    component: <Operator />,
  },
  {
    id: 'applicant',
    disablePadding: true,
    label: 'Alerts',
    component: <ApplicantColumn />,
  },
];

export function Reports() {
  const { rows, loading, initialized } = useFamilies();

  return (
    <>
      <Helmet title='Reports' />
      <Header
      // actionButton={
      //   // <div style={{ width: '200px' }}>
      //   //   <SelectFormField
      //   //     label='Select the family'
      //   //     options={opt}
      //   //     setSelectedFamily={setSelectedFamily}
      //   //   />
      //   // </div>
      // }
      >
        Reports
      </Header>
      <DataView
        contextName='reports'
        headCells={headCells}
        initialized={initialized}
        loading={loading}
        options={{ enableDelete: false }}
        rows={rows}
      />
    </>
  );
}
