import { TreeDialogMode } from 'types/data';
import { create, StoreApi, UseBoundStore } from 'zustand';

type TabStore = {
  currentId: string;
  currentMemberId: string | null;
  currentMarriageId: string[] | null;
  mode: TreeDialogMode;
  setMode: (mode: TreeDialogMode) => void;
  setCurrentId: (id: string) => void;
  setCurrentMemberId: (id: string | null) => void;
  setCurrentMarriageId: (id: string[] | null) => void;
};

export const useTabStore: UseBoundStore<StoreApi<TabStore>> = create((set) => {
  return {
    currentId: '',
    currentMemberId: null,
    currentMarriageId: null,
    mode: TreeDialogMode.NEW,
    setMode: (mode: TreeDialogMode) => set(() => ({ mode })),
    setCurrentId: (id: string) => set(() => ({ currentId: id })),
    setCurrentMemberId: (id: string | null) =>
      set(() => ({ currentMemberId: id })),
    setCurrentMarriageId: (id: string[] | null) =>
      set(() => ({ currentMarriageId: id })),
  };
});
