// material-ui
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';

// ==============================|| SKELETON - TOTAL INCOME DARK/LIGHT CARD ||============================== //

const TotalIncomeCard = () => (
  <Card sx={{ p: 2 }}>
    <List sx={{ py: 0 }}>
      <ListItem alignItems='center' disableGutters sx={{ py: 0 }}>
        <ListItemAvatar>
          <Skeleton height={44} variant='rectangular' width={44} />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton height={20} variant='rectangular' />}
          secondary={<Skeleton variant='text' />}
          sx={{ py: 0 }}
        />
      </ListItem>
    </List>
  </Card>
);

export default TotalIncomeCard;
