import Logout from '@mui/icons-material/Logout';
import PushPinIcon from '@mui/icons-material/PushPin';
import Settings from '@mui/icons-material/Settings';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import React, { HTMLProps } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Auth } from '../../config/Auth';
import { customColor } from '../../theme/variants';
import { CustomButton } from '../atoms/CustomButton';
import { usePinned } from '../hooks/usePinned';

const Container = styled.div`
  background-color: ${customColor[900]};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 0.5rem;
  box-sizing: border-box;
  position: fixed;
  z-index: 1000;
`;

export function TopBar(props: HTMLProps<HTMLDivElement>) {
  const { username } = Auth.getCredentials();
  const { pinned } = usePinned();
  const navigate = useNavigate();
  const pins = Array.from(pinned);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    handleClose();
    navigate('/auth/login');
  };

  return (
    <Container {...props}>
      {pins.length > 0 && (
        <Box mr={5}>
          {pins.map((pin) => (
            <CustomButton
              color='secondary'
              key={`pin-${pin.id}`}
              onClick={() => navigate(`/families/${pin.id}`)}
              size='small'
              startIcon={<PushPinIcon />}
              sx={{ margin: '0 0.5rem' }}
            >
              {pin.label}
            </CustomButton>
          ))}
        </Box>
      )}
      <div id='background-processes' /> {/** Background processes  **/}
      {username && (
        <IconButton
          aria-controls={open ? 'account-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
          size='small'
          sx={{ ml: 2 }}
        >
          <Avatar>{username.substring(0, 2).toUpperCase()}</Avatar>
        </IconButton>
      )}
      <Menu
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        id='account-menu'
        onClick={handleClose}
        onClose={handleClose}
        open={open}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize='small' />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Container>
  );
}
