import { Box, CircularProgress } from '@mui/material';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { styled } from 'styled-components';
import { statusColor } from 'theme/variants';
import { QRCodeState } from 'types/State';
import { grayScale } from 'theme/variants';

export interface DeletableImageProps {
  error?: boolean;
  handleDelete: (imageUrl: string) => void;
  imageUrl: string;
  loading: boolean;
  warning?: string;
}

const ResizeImg = styled.img`
  height: 100%;
  border-radius: 12px;
`;

const StatusBorder = styled.span`
  height: 100%;
  border-radius: 12px;

  .error-border {
    margin-top: -2px;
    border: 2px solid ${statusColor[QRCodeState.ERROR]};
  }
  .warning-border {
    margin-top: -2px;
    border: 2px solid ${statusColor[QRCodeState.WARNING]};
  }
  .success-border {
    margin-top: -2px;
    border: 2px solid ${statusColor[QRCodeState.SUCCESS]};
  }
`;

const LoadingContainer = styled.span`
  background-color: ${grayScale[60]};
  opacity: 0.5;
  position: absolute;
  height: 100%;
  width: 100%;
`;

function DeletableImage({
  error,
  handleDelete,
  imageUrl,
  loading,
  warning,
}: DeletableImageProps) {
  return (
    <Box
      display='flex'
      height='calc(100% - 80px)'
      minWidth='125px'
      position='relative'
      width='fit-content'
    >
      {loading ? (
        <LoadingContainer>
          <Box
            alignItems='center'
            display='flex'
            height='100%'
            justifyContent='center'
            width='100%'
          >
            <CircularProgress />
          </Box>
        </LoadingContainer>
      ) : (
        <></>
      )}
      <Box
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(imageUrl);
        }}
        position='absolute'
        right='-15px'
        top='-15px'
      >
        <CancelSharpIcon />
      </Box>
      <StatusBorder>
        <ResizeImg
          className={
            error
              ? 'error-border'
              : warning
                ? 'warning-border'
                : 'success-border'
          }
          src={imageUrl}
        />
      </StatusBorder>
    </Box>
  );
}

export default DeletableImage;
