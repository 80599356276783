import { useQuery } from '@tanstack/react-query';
import { useFamily } from 'components/hooks/useFamily';
import { Api } from '../../api/Api';
import Configuration from '../../config/Configuration';
import { FamilyTreeCardProps } from '../molecules/FamilyTreeCard';
import { FamilyTreeMember } from 'components/contexts/FamilyContext';

export type ReportDocument = {
  memberId: string;
  memberName: string;
  values: string[];
  fields: string[];
  document: string;
  errorType: string;
  errorMessage: string;
  operation: string;
};

interface CNN {
  field: string;
  member: FamilyTreeMember;
  value: string;
}

export type ReportData = {
  reportDocuments: ReportDocument[];
  rootMember: FamilyTreeCardProps;
  applicant?: FamilyTreeCardProps;
  embassies: string[];
  cnn: CNN[];
};

const api = new Api();
const config = new Configuration();

export function useReport() {
  const { family } = useFamily();

  const queryKey = ['reports', family._id];

  const {
    isLoading: loading,
    isFetched: initialized,
    data,
    refetch: fetchData,
  } = useQuery<ReportData | null>({
    queryKey,
    queryFn: async () => {
      if (!family._id) {
        return null;
      }
      const targetUrl = config.getRouteWithVars(config.endpoint.get.report, {
        familyId: family._id,
      });

      const response = await api.get(targetUrl);

      return response.data as ReportData;
    },
  });

  return { loading, initialized, data, fetchData };
}
