import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// project imports
import BajajAreaChartCard from './BajajAreaChartCard';
import MainCard from '../../ui-component/cards/MainCard';
import SkeletonPopularCard from '../../ui-component/cards/Skeleton/PopularCard';
import { gridSpacing } from '../../store/constant';

// assets
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const PopularCard = ({ isLoading }) => {
  return (
    <>
      {isLoading ? (
        <SkeletonPopularCard />
      ) : (
        <MainCard content={false}>
          <CardContent>
            <Grid container spacing={gridSpacing}>
              <Grid item sx={{ pt: '16px !important' }} xs={12}>
                <BajajAreaChartCard />
              </Grid>
              <Grid item xs={12}>
                <Grid container direction='column'>
                  <Grid item>
                    <Grid
                      alignItems='center'
                      container
                      justifyContent='space-between'
                    >
                      <Grid item>
                        <Typography color='inherit' variant='subtitle1'>
                          Family 1
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          alignItems='center'
                          container
                          justifyContent='space-between'
                        >
                          <Grid item>
                            <Typography color='inherit' variant='subtitle1'>
                              $1839.00
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Avatar
                              sx={{
                                width: 16,
                                height: 16,
                                borderRadius: '5px',
                                bgcolor: 'success.light',
                                color: 'success.dark',
                                ml: 2,
                              }}
                              variant='rounded'
                            >
                              <KeyboardArrowUpOutlinedIcon
                                color='inherit'
                                fontSize='small'
                              />
                            </Avatar>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{ color: 'success.dark' }}
                      variant='subtitle2'
                    >
                      10% Profit
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1.5 }} />
                <Grid container direction='column'>
                  <Grid item>
                    <Grid
                      alignItems='center'
                      container
                      justifyContent='space-between'
                    >
                      <Grid item>
                        <Typography color='inherit' variant='subtitle1'>
                          Family 2
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          alignItems='center'
                          container
                          justifyContent='space-between'
                        >
                          <Grid item>
                            <Typography color='inherit' variant='subtitle1'>
                              $100.00
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Avatar
                              sx={{
                                width: 16,
                                height: 16,
                                borderRadius: '5px',
                                bgcolor: 'orange.light',
                                color: 'orange.dark',
                                marginLeft: 1.875,
                              }}
                              variant='rounded'
                            >
                              <KeyboardArrowDownOutlinedIcon
                                color='inherit'
                                fontSize='small'
                              />
                            </Avatar>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{ color: 'orange.dark' }}
                      variant='subtitle2'
                    >
                      10% profit
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1.5 }} />
                <Grid container direction='column'>
                  <Grid item>
                    <Grid
                      alignItems='center'
                      container
                      justifyContent='space-between'
                    >
                      <Grid item>
                        <Typography color='inherit' variant='subtitle1'>
                          Family 3
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          alignItems='center'
                          container
                          justifyContent='space-between'
                        >
                          <Grid item>
                            <Typography color='inherit' variant='subtitle1'>
                              $200.00
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Avatar
                              sx={{
                                width: 16,
                                height: 16,
                                borderRadius: '5px',
                                bgcolor: 'success.light',
                                color: 'success.dark',
                                ml: 2,
                              }}
                              variant='rounded'
                            >
                              <KeyboardArrowUpOutlinedIcon
                                color='inherit'
                                fontSize='small'
                              />
                            </Avatar>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{ color: 'success.dark' }}
                      variant='subtitle2'
                    >
                      10% Profit
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1.5 }} />
                <Grid container direction='column'>
                  <Grid item>
                    <Grid
                      alignItems='center'
                      container
                      justifyContent='space-between'
                    >
                      <Grid item>
                        <Typography color='inherit' variant='subtitle1'>
                          Family 4
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          alignItems='center'
                          container
                          justifyContent='space-between'
                        >
                          <Grid item>
                            <Typography color='inherit' variant='subtitle1'>
                              $189.00
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Avatar
                              sx={{
                                width: 16,
                                height: 16,
                                borderRadius: '5px',
                                bgcolor: 'orange.light',
                                color: 'orange.dark',
                                ml: 2,
                              }}
                              variant='rounded'
                            >
                              <KeyboardArrowDownOutlinedIcon
                                color='inherit'
                                fontSize='small'
                              />
                            </Avatar>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{ color: 'orange.dark' }}
                      variant='subtitle2'
                    >
                      10% profit
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1.5 }} />
                <Grid container direction='column'>
                  <Grid item>
                    <Grid
                      alignItems='center'
                      container
                      justifyContent='space-between'
                    >
                      <Grid item>
                        <Typography color='inherit' variant='subtitle1'>
                          Family 5
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          alignItems='center'
                          container
                          justifyContent='space-between'
                        >
                          <Grid item>
                            <Typography color='inherit' variant='subtitle1'>
                              $189.00
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Avatar
                              sx={{
                                width: 16,
                                height: 16,
                                borderRadius: '5px',
                                bgcolor: 'orange.light',
                                color: 'orange.dark',
                                ml: 2,
                              }}
                              variant='rounded'
                            >
                              <KeyboardArrowDownOutlinedIcon
                                color='inherit'
                                fontSize='small'
                              />
                            </Avatar>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{ color: 'orange.dark' }}
                      variant='subtitle2'
                    >
                      10% profit
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1.5 }} />
                <Grid container direction='column'>
                  <Grid item>
                    <Grid
                      alignItems='center'
                      container
                      justifyContent='space-between'
                    >
                      <Grid item>
                        <Typography color='inherit' variant='subtitle1'>
                          Family 6
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          alignItems='center'
                          container
                          justifyContent='space-between'
                        >
                          <Grid item>
                            <Typography color='inherit' variant='subtitle1'>
                              $189.00
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Avatar
                              sx={{
                                width: 16,
                                height: 16,
                                borderRadius: '5px',
                                bgcolor: 'orange.light',
                                color: 'orange.dark',
                                ml: 2,
                              }}
                              variant='rounded'
                            >
                              <KeyboardArrowDownOutlinedIcon
                                color='inherit'
                                fontSize='small'
                              />
                            </Avatar>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{ color: 'orange.dark' }}
                      variant='subtitle2'
                    >
                      10% profit
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
            <Button disableElevation size='small'>
              View All
              <ChevronRightOutlinedIcon />
            </Button>
          </CardActions>
        </MainCard>
      )}
    </>
  );
};

PopularCard.propTypes = {
  isLoading: PropTypes.bool,
};

export default PopularCard;
