import { TABS } from 'components/contexts/TabContext';
import { useTabStore } from 'components/stores/useTabStore';
import { useUploadStore } from 'components/stores/useUploadStore';
import { useCallback, useEffect, useRef } from 'react';
import { TreeDialogMode } from 'types/data';
import { useFamily } from './useFamily';
import { useTab } from './useTab';
import { useUpload } from './useUpload';

type Member = {
  parents: string[];
  gender: string;
  isApplicant: string;
};

type NextStep = {
  gender: string;
  parents: string;
  isApplicant?: boolean;
};

/**
 * Custom hook for managing the upload tab functionality.
 * This hook provides functions and data related to handling file uploads and managing the state of the upload tab.
 * It uses other custom hooks like `useFamily`, `useTab`, and `useUpload` to handle specific aspects of the upload process.
 * @returns An object containing the necessary functions and data for the upload tab.
 */
export function useUploadTab() {
  const { members, tree, createNewMember, updateMember, manuallyDocument } =
    useFamily();
  const { setCurrentId, currentMemberId, currentMarriageId } = useTab();

  const mode = useTabStore((state) => state.mode);

  const member = tree.find(
    (member) => (member as unknown as { id: string }).id === currentMemberId
  ) as Member | undefined;

  const isHighest =
    mode === TreeDialogMode.NEW
      ? !members.length
      : member?.parents?.length === 0;

  const formRef = useRef<HTMLFormElement | null>(null);
  const { uploadFiles } = useUpload();

  const files = useUploadStore((state) => state.files);
  const setFiles = useUploadStore((state) => state.setFiles);

  /**
   * Function to handle the selection of files.
   * Adds the selected files to the list of files to be uploaded.
   * @param _files - The files to be added to the upload list.
   */
  const handleFiles = (_files: File[]) => {
    setFiles([...files, ..._files]);
  };

  /**
   * Uploads files for a specific member.
   * @param {Object} options - The options for uploading files.
   * @param {string} options.memberId - The ID of the member.
   */
  const doUploadFiles = useCallback(
    ({ memberId }: { memberId: string }) => {
      uploadFiles({
        files,
        memberId,
        onProgress: ({ progress, file }) => {
          setFiles(
            files.map((_file) => {
              if (_file === file) {
                file.progress = progress;
              }

              return file;
            })
          );
        },
      }).then(() => {
        setCurrentId(TABS.SECOND);
      });
    },
    [uploadFiles, files, setFiles, setCurrentId]
  );

  const nextStepManually = useCallback(
    async ({ gender, parents, isApplicant = false }: NextStep) => {
      const memberId =
        currentMemberId ??
        ((await createNewMember({
          gender,
          parents,
          isApplicant,
        })) as string);

      manuallyDocument(memberId).then(() => {
        setCurrentId(TABS.THIRD);
      });
    },
    [createNewMember, currentMemberId, manuallyDocument, setCurrentId]
  );
  /**
   * Function to handle the next step in the upload process.
   * If there are no files selected, the function returns early.
   * If there is a current member ID, it uses that ID. Otherwise, it creates a new member and uses the returned ID.
   * It then uploads the files, updating the progress for each file, and finally sets the current ID to the second tab.
   */
  const nextStep = useCallback(
    async ({ gender, parents, isApplicant = false }: NextStep) => {
      if (mode === TreeDialogMode.NEW) {
        if (files.length === 0 || (!isHighest && parents.length === 0)) {
          return;
        }

        const memberId =
          currentMemberId ??
          ((await createNewMember({
            gender,
            parents,
            isApplicant,
          })) as string);

        doUploadFiles({ memberId });
      } else {
        updateMember({
          memberId: currentMemberId as string,
          gender,
          parents,
          isApplicant,
        });

        if (files.length > 0) {
          doUploadFiles({ memberId: currentMemberId as string });
        } else {
          setCurrentId(TABS.THIRD);
        }
      }
    },
    [
      mode,
      files.length,
      isHighest,
      currentMemberId,
      createNewMember,
      doUploadFiles,
      updateMember,
      setCurrentId,
    ]
  );

  useEffect(() => {
    setFiles([]);
  }, [setFiles]);

  return {
    files,
    formRef,
    currentMember: member,
    handleFiles,
    members,
    nextStep,
    isHighest,
    currentMarriageId,
    nextStepManually,
  };
}
