import { Header } from 'components/molecules/Header';

export const Legislation = () => {
  const links = [
    {
      url: 'https://www.esteri.it/it/',
      description:
        'Ministero degli Affari Esteri e della Cooperazione Internazionale',
    },
    {
      url: 'https://www.interno.gov.it/it/temi/cittadinanza-e-altri-diritti-civili/cittadinanza',
      description:
        "Ministero dell'Interno - Cittadinanza e altri diritti civili",
    },
    {
      url: 'https://www.esteri.it/it/servizi-consolari-e-visti/italiani-all-estero/cittadinanza/',
      description: 'Servizi Consolari e Visti - Cittadinanza',
    },
    {
      url: 'https://www.normattiva.it/uri-res/N2Ls?urn:nir:stato:legge:1992;91',
      description: 'Legge 1992, n. 91 - Normattiva',
    },
    {
      url: 'https://www.jusbrasil.com.br/artigos/cidadania-italiana-circolare-k-28-a-lei-que-regulamenta-o-pedido-da-nacionalidade/1153443217',
      description: 'Cidadania Italiana - Jusbrasil',
    },
  ];

  return (
    <div>
      <Header>Useful Links</Header>
      <ul>
        {links.map((link, index) => (
          <li key={index} style={{ margin: '15px 0' }}>
            <a href={link.url} rel='noopener noreferrer' target='_blank'>
              {link.description}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
