// material-ui
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

// ==============================|| SKELETON - EARNING CARD ||============================== //

const EarningCard = () => (
  <Card>
    <CardContent>
      <Grid container direction='column'>
        <Grid item>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Skeleton height={44} variant='rectangular' width={44} />
            </Grid>
            <Grid item>
              <Skeleton height={34} variant='rectangular' width={34} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Skeleton height={40} sx={{ my: 2 }} variant='rectangular' />
        </Grid>
        <Grid item>
          <Skeleton height={30} variant='rectangular' />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default EarningCard;
