import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  AlertColor,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useFamily } from 'components/hooks/useFamily';
import { ReportDocument, useReport } from 'components/hooks/useReport';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import reportKeys from '../../config/reportKeys.json';
import { Header } from '../molecules/Header';

type ReportKeys = {
  [key: string]: string;
};

interface GroupedData {
  memberId: string;
  memberName: string;
  documents: {
    [document: string]: ReportDocument[];
  };
}

const CustomCard = styled(Card)`
  && {
    background: white;
  }
`;

const reportKeysMapped = reportKeys as ReportKeys;

function ReportContent() {
  const { data } = useReport();

  const groupDocuments = useMemo<GroupedData[]>(() => {
    const groupedDataMap: { [memberId: string]: GroupedData } = {};

    data?.reportDocuments.forEach((item) => {
      if (!groupedDataMap[item.memberId]) {
        groupedDataMap[item.memberId] = {
          memberId: item.memberId,
          memberName: item.memberName,
          documents: {},
        };
      }

      if (!groupedDataMap[item.memberId].documents[item.document]) {
        groupedDataMap[item.memberId].documents[item.document] = [];
      }

      groupedDataMap[item.memberId].documents[item.document].push(item);
    });

    const groupedData = Object.values(groupedDataMap);

    // Sort documents within each memberId group
    groupedData.forEach((group) => {
      group.documents = Object.fromEntries(
        Object.entries(group.documents).sort(([docA], [docB]) =>
          docA.localeCompare(docB)
        )
      );
    });

    return groupedData;
  }, [data?.reportDocuments]);

  const isISODateString = (value: string) => {
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    if (!value) return;

    if (isoDatePattern.test(value)) {
      const date = new Date(value);
      const formattedDate =
        date.getFullYear() +
        '/' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '/' +
        date.getDate().toString().padStart(2, '0');

      return formattedDate;
    } else {
      return value;
    }
  };

  const gender = {
    male: [
      'birthCertificate.birthCertificateId.fullName',
      'marriageCertificate.marriageCertificateId.male.fullName',
      'deathCertificate.deathCertificateId.fullName',
      'birthCertificate.birthCertificateId.father.fullName',
      'marriageCertificate.marriageCertificateId.male.father.fullName',
      'deathCertificate.deathCertificateId.father.fullName',
      'birthCertificate.birthCertificateId.father.father.fullName',
    ],
    female: [
      'birthCertificate.birthCertificateId.fullName',
      'marriageCertificate.marriageCertificateId.female.fullName',
      'deathCertificate.deathCertificateId.fullName',
      'birthCertificate.birthCertificateId.mother.fullName',
      'marriageCertificate.marriageCertificateId.female.mother.fullName',
      'deathCertificate.deathCertificateId.mother.fullName',
      'birthCertificate.birthCertificateId.mother.mother.fullName',
    ],
  };

  const values: string[] = [];

  const cities = [
    'aiello del friuli',
    'aquileia',
    'campolongo al torre',
    'cervignano del friuli',
    'chiopris-viscone',
    'fiumicello',
    'malborghetto-valbruna',
    'pontebba',
    'ruda',
    'san vito al torre',
    'tapogliano',
    'tarvisio',
    'terzo di aquileia',
    'villa vicentina',
    'visco',
    "cortina d'ampezzo",
    'colle santa lucia',
    'livinallongo del col di lana',
    'pedemonte',
    'casotto',
    'magasa',
    'valvestino',
  ];

  const states = [
    'bz',
    'bolzano',
    'tn',
    'trento',
    'go',
    'gorizia',
    'ts',
    'trieste',
  ];

  const checkIfCityIsAustroHugaroEmperor = () => {
    const city =
      data?.rootMember.documents[
        'birthCertificate.birthCertificateId.placeOfBirth.city'
      ]?.toLowerCase();

    return cities.includes(city);
  };

  const checkIfStateIsAustroHugaroEmperor = () => {
    const state =
      data?.rootMember.documents[
        'birthCertificate.birthCertificateId.placeOfBirth.state'
      ]?.toLowerCase();

    return states.includes(state);
  };

  return (
    <>
      <Box mt={6}>
        <CustomCard color='primary' elevation={3}>
          <CardContent>
            <Typography variant='h2'>Highest Ancestor</Typography>
            <Divider sx={{ margin: '1rem 0' }} />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '200px' }}>
                      <strong>ID</strong>
                    </TableCell>
                    <TableCell>{data?.rootMember.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell>{data?.rootMember.name}</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </CardContent>
        </CustomCard>
      </Box>

      <Box mt={6}>
        <CustomCard color='primary' elevation={3}>
          <CardContent>
            <Typography variant='h2'>CNN</Typography>
            <Divider sx={{ margin: '1rem 0' }} />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  {data?.rootMember.gender === 'male' &&
                    data?.cnn.map((e, i) => {
                      if (gender.male.find((x) => x === e.field)) {
                        values.push(e.value);

                        return (
                          <TableRow
                            key={`cnn-${i}`}
                            style={{ display: 'none' }}
                          >
                            <TableCell>
                              <strong>{e.field}</strong>
                            </TableCell>
                            <TableCell>{e.value}</TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  {data?.rootMember.gender === 'female' &&
                    data?.cnn.map((e, i) => {
                      if (gender.female.find((x) => x === e.field)) {
                        values.push(e.value);

                        return (
                          <TableRow
                            key={`cnn-${i}`}
                            style={{ display: 'none' }}
                          >
                            <TableCell>
                              <strong>{e.field}</strong>
                            </TableCell>
                            <TableCell>{e.value}</TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  <TableRow>
                    <TableCell sx={{ width: '200px' }}>
                      <strong>Message</strong>
                    </TableCell>
                    <TableCell>
                      <div style={{ paddingBottom: '0.5em' }}>
                        A CNN (Certidão Negativa de Naturalização) deve conter
                        as seguintes variações de nome:
                      </div>
                      {Array.from(new Set(values)).map((value, i) => (
                        <Chip
                          color='success'
                          key={`values-${i}`}
                          label={value}
                          sx={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
                          variant='outlined'
                        />
                      ))}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </CardContent>
        </CustomCard>
      </Box>
      {/* estoy */}
      {(checkIfCityIsAustroHugaroEmperor() ||
        checkIfStateIsAustroHugaroEmperor()) && (
        <Box mt={6}>
          <CustomCard color='primary' elevation={3}>
            <CardContent>
              <Typography variant='h2'>Império Austro Húngaro</Typography>
              <Divider sx={{ margin: '1rem 0' }} />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    {checkIfCityIsAustroHugaroEmperor() && (
                      <TableRow>
                        <TableCell sx={{ width: '100px' }}>
                          <strong>Message</strong>
                        </TableCell>
                        <TableCell sx={{ width: '100px' }}>
                          <Chip
                            color='success'
                            key='values-chip}'
                            label={data?.rootMember.documents[
                              'birthCertificate.birthCertificateId.placeOfBirth.city'
                            ].toLowerCase()}
                            sx={{
                              marginRight: '0.5rem',
                              marginBottom: '0.5rem',
                            }}
                            variant='outlined'
                          />{' '}
                        </TableCell>
                        <TableCell>
                          <div style={{ paddingBottom: '0.5em' }}>
                            Esta Comune Faz Parte do Império Austro Húngaro -
                            Integrar no processo comprovante de Nascimento ou
                            Certificado de Imigração anterior a data de
                            25/12/1867, ou posterior a data de 16/07/1920.
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                    {checkIfStateIsAustroHugaroEmperor() && (
                      <TableRow>
                        <TableCell sx={{ width: '100px' }}>
                          <strong>Message</strong>
                        </TableCell>
                        <TableCell sx={{ width: '100px' }}>
                          <Chip
                            color='success'
                            key='values-chip'
                            label={data?.rootMember.documents[
                              'birthCertificate.birthCertificateId.placeOfBirth.state'
                            ].toLowerCase()}
                            sx={{
                              marginRight: '0.5rem',
                              marginBottom: '0.5rem',
                            }}
                            variant='outlined'
                          />{' '}
                        </TableCell>
                        <TableCell>
                          <div style={{ paddingBottom: '0.5em' }}>
                            Esta Província e Todas as Comunes relacionadas fazem
                            parte do Império Austro Húngaro - Integrar no
                            processo comprovante de Nascimento ou Certificado de
                            Imigração anterior a data de 25/12/1867, ou
                            posterior a data de 16/07/1920.
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                </Table>
              </TableContainer>
            </CardContent>
          </CustomCard>
        </Box>
      )}
      {data?.embassies?.length ? (
        <Box mt={6}>
          <CustomCard color='primary' elevation={3}>
            <CardContent>
              <Typography variant='h2'>Embassies / Consulates</Typography>
              <Divider sx={{ margin: '1rem 0' }} />
              <Grid container spacing={2}>
                {data?.embassies.map((embassy, idx) => (
                  <Grid item key={`embassy-${idx}`} xs={2}>
                    <Chip label={embassy} />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </CustomCard>
        </Box>
      ) : (
        <></>
      )}
      {groupDocuments.map((group, index) => (
        <Box key={`documents-${index}`} mt={6}>
          <CustomCard color='primary' elevation={3}>
            <CardContent>
              <Typography variant='h2'>{group.memberName}</Typography>
              <Divider sx={{ margin: '1rem 0' }} />
              <Grid container spacing={2}>
                {Object.entries<ReportDocument[]>(group.documents).map(
                  ([documentName, documents], idx) => (
                    <Grid item key={`document-${idx}`} xs={12}>
                      <Typography variant='h4'>{documentName}</Typography>
                      <Divider sx={{ margin: '1rem 0' }} />
                      {documents.map((document, subIdx) => (
                        <Grid item key={`document-${idx}-${subIdx}`} xs={12}>
                          <Box my={4}>
                            <Alert severity={document.errorType as AlertColor}>
                              {document.errorMessage}
                            </Alert>
                            <TableContainer
                              component={Paper}
                              sx={{ marginTop: '-0.2rem' }}
                            >
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ width: '200px' }}>
                                      <strong>Member</strong>
                                    </TableCell>
                                    <TableCell>{document.memberName}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <strong>Fields</strong>
                                    </TableCell>
                                    <TableCell>
                                      <div style={{ display: 'flex' }}>
                                        <Chip
                                          label={
                                            reportKeysMapped[
                                              document.fields[0]
                                            ] ?? document.fields[0]
                                          }
                                          sx={{
                                            marginRight: '0.5rem',
                                          }}
                                        />
                                        {document.fields[1] &&
                                          document.fields[1].length && (
                                            <>
                                              <CloseIcon
                                                sx={{
                                                  marginRight: '0.5rem',
                                                  marginTop: '0.2rem',
                                                }}
                                              />
                                              <Chip
                                                label={
                                                  reportKeysMapped[
                                                    document.fields[1]
                                                  ] ?? document.fields[1]
                                                }
                                              />
                                            </>
                                          )}
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                  {document.operation !== 'custom' && (
                                    <TableRow>
                                      <TableCell>
                                        <strong>Compared values</strong>
                                      </TableCell>
                                      <TableCell>
                                        {document.values.map((value, idx) => (
                                          <Chip
                                            color={
                                              idx === 0 ? 'success' : 'error'
                                            }
                                            key={`values-${idx}`}
                                            label={isISODateString(value)}
                                            sx={{ marginRight: '0.5rem' }}
                                            variant='outlined'
                                          />
                                        ))}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableHead>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  )
                )}
              </Grid>
            </CardContent>
          </CustomCard>
        </Box>
      ))}
    </>
  );
}

export function Report() {
  const { family } = useFamily();

  return (
    <>
      <Helmet title='Report' />
      <Header>{`Report | ${family.familyName ?? ''}`}</Header>
      {family && family.hasApplicant && <ReportContent />}
      {family && !family.hasApplicant && (
        <Box mt={6}>
          <Alert severity='error'>No Applicant found</Alert>
        </Box>
      )}
    </>
  );
}
