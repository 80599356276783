import { Alert, Box, FormLabel, Grid } from '@mui/material';
import { useTab } from 'components/hooks/useTab';
import { useUploadTab } from 'components/hooks/useUploadTab';
import { Gender } from 'components/molecules/FamilyTree/Form/Gender';
import { MemberType } from 'components/molecules/FamilyTree/Form/MemberType';
import { Parents } from 'components/molecules/FamilyTree/Form/Parents';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import UploadBox from '../../../atoms/UploadBox';
import UploadedFile from '../../../atoms/UploadedFile';

export function UploadTab() {
  const {
    isHighest,
    files,
    formRef,
    handleFiles,
    members,
    nextStep,
    currentMarriageId,
    currentMember,
    nextStepManually,
  } = useUploadTab();

  const { onSubmit, onSecondSubmit } = useTab();

  const { handleSubmit, control, getValues, setValue } = useForm({
    defaultValues: {
      gender: currentMember?.gender ?? 'female',
      type: isHighest ? 'highest' : 'member',
      parents: currentMarriageId?.join(',') ?? '',
    },
  });

  const handleSubmission = () => {
    nextStep({
      gender: getValues('gender'),
      parents: getValues('parents'),
      isApplicant: getValues('type') === 'applicant',
    });
  };

  if (onSubmit) {
    onSubmit.current = async () => {
      handleSubmission();
    };
  }

  if (onSecondSubmit) {
    onSecondSubmit.current = async () => {
      nextStepManually({
        gender: getValues('gender'),
        parents: getValues('parents'),
        isApplicant: getValues('type') === 'applicant',
      });
    };
  }

  useEffect(() => {
    if (!currentMember) {
      return;
    }

    currentMember?.gender && setValue('gender', currentMember?.gender);
    currentMember?.isApplicant && setValue('type', 'applicant');
  }, [currentMember, control, setValue]);

  console.log('>> files', files);

  return (
    <>
      {!members.length && (
        <Alert severity='warning'>
          You must add the highest ancestor before adding the other ones
        </Alert>
      )}
      {!isHighest && !getValues('parents').length && (
        <Alert severity='error'>
          You must add select the parents of the member
        </Alert>
      )}
      <Box mt={5} px={4}>
        <form onSubmit={handleSubmit(handleSubmission)} ref={formRef}>
          <Grid container spacing={10}>
            {!isHighest && (
              <Parents control={control} required={members.length > 0} />
            )}
            <MemberType control={control} />
            <Gender control={control} />
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box my={5}>
                <FormLabel>Documents</FormLabel>
              </Box>
              <Box my={5}>
                <UploadBox onDrop={handleFiles} />
              </Box>
              <Grid container my={5} spacing={6}>
                {files.map((file, index) => (
                  <Grid item key={`file-${file.name}-${index}`} xs={6}>
                    <UploadedFile
                      fileName={file.name}
                      fileSize={`${Math.round(file.size / 1024)} KB`}
                      progress={file.progress}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
