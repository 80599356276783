// material-ui
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

// project imports
import { gridSpacing } from '../../../store/constant';

// ==============================|| SKELETON - POPULAR CARD ||============================== //

const PopularCard = () => (
  <Card>
    <CardContent>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid
            alignItems='center'
            container
            justifyContent='space-between'
            spacing={gridSpacing}
          >
            <Grid item xs zeroMinWidth>
              <Skeleton height={20} variant='rectangular' />
            </Grid>
            <Grid item>
              <Skeleton height={20} variant='rectangular' width={20} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Skeleton height={150} variant='rectangular' />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                alignItems='center'
                container
                justifyContent='space-between'
                spacing={gridSpacing}
              >
                <Grid item xs={6}>
                  <Skeleton height={20} variant='rectangular' />
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    alignItems='center'
                    container
                    justifyContent='space-between'
                    spacing={gridSpacing}
                  >
                    <Grid item xs zeroMinWidth>
                      <Skeleton height={20} variant='rectangular' />
                    </Grid>
                    <Grid item>
                      <Skeleton height={16} variant='rectangular' width={16} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Skeleton height={20} variant='rectangular' />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                alignItems='center'
                container
                justifyContent='space-between'
                spacing={gridSpacing}
              >
                <Grid item xs={6}>
                  <Skeleton height={20} variant='rectangular' />
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    alignItems='center'
                    container
                    justifyContent='space-between'
                    spacing={gridSpacing}
                  >
                    <Grid item xs zeroMinWidth>
                      <Skeleton height={20} variant='rectangular' />
                    </Grid>
                    <Grid item>
                      <Skeleton height={16} variant='rectangular' width={16} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Skeleton height={20} variant='rectangular' />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                alignItems='center'
                container
                justifyContent='space-between'
                spacing={gridSpacing}
              >
                <Grid item xs={6}>
                  <Skeleton height={20} variant='rectangular' />
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    alignItems='center'
                    container
                    justifyContent='space-between'
                    spacing={gridSpacing}
                  >
                    <Grid item xs zeroMinWidth>
                      <Skeleton height={20} variant='rectangular' />
                    </Grid>
                    <Grid item>
                      <Skeleton height={16} variant='rectangular' width={16} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Skeleton height={20} variant='rectangular' />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                alignItems='center'
                container
                justifyContent='space-between'
                spacing={gridSpacing}
              >
                <Grid item xs={6}>
                  <Skeleton height={20} variant='rectangular' />
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    alignItems='center'
                    container
                    justifyContent='space-between'
                    spacing={gridSpacing}
                  >
                    <Grid item xs zeroMinWidth>
                      <Skeleton height={20} variant='rectangular' />
                    </Grid>
                    <Grid item>
                      <Skeleton height={16} variant='rectangular' width={16} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Skeleton height={20} variant='rectangular' />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                alignItems='center'
                container
                justifyContent='space-between'
                spacing={gridSpacing}
              >
                <Grid item xs={6}>
                  <Skeleton height={20} variant='rectangular' />
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    alignItems='center'
                    container
                    justifyContent='space-between'
                    spacing={gridSpacing}
                  >
                    <Grid item xs zeroMinWidth>
                      <Skeleton height={20} variant='rectangular' />
                    </Grid>
                    <Grid item>
                      <Skeleton height={16} variant='rectangular' width={16} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Skeleton height={20} variant='rectangular' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
    <CardContent
      sx={{ p: 1.25, display: 'flex', pt: 0, justifyContent: 'center' }}
    >
      <Skeleton height={25} variant='rectangular' width={75} />
    </CardContent>
  </Card>
);

export default PopularCard;
