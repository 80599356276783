import { Box, CircularProgress, Dialog, Typography } from '@mui/material';
import { NewButton } from './NewButton';

export interface DownloadPdfDialogProps {
  isOpen: boolean;
  pdfURL: string;
  handleClose: () => void;
}

function DownloadPdfDialog({
  isOpen,
  pdfURL,
  handleClose,
}: DownloadPdfDialogProps) {
  const handleDownload = () => {
    const tempLink = document.createElement('a');

    tempLink.href = pdfURL;
    tempLink.setAttribute(
      'download',
      `validate-${new Date().toISOString()}.pdf`
    );
    tempLink.click();
  };

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box
        alignItems='center'
        display='flex'
        height='200px'
        justifyContent='center'
        width='300px'
      >
        {pdfURL ? (
          <Box
            alignItems='center'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            p={10}
          >
            <Box>
              <Typography textAlign='center'>
                The pdf file of your validation is ready to download
              </Typography>
            </Box>
            <Box mt={2} width='fit-content'>
              <NewButton
                fullWidth={false}
                href={pdfURL}
                onClick={handleDownload}
              >
                Download PDF
              </NewButton>
            </Box>
          </Box>
        ) : (
          <Box display='flex' flexDirection='column'>
            <Box
              alignItems='center'
              display='flex'
              flexDirection='column'
              mb={4}
            >
              <CircularProgress />
            </Box>
            <Typography>Loading validation for your files</Typography>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}

export default DownloadPdfDialog;
