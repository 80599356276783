import ArticleIcon from '@mui/icons-material/Article';
import PushPinIcon from '@mui/icons-material/PushPin';
import { IconButton, LinearProgress } from '@mui/material';
import { useTabStore } from 'components/stores/useTabStore';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TreeDialogMode } from 'types/data';
import { customColor } from '../../theme/variants';
import { CustomButton } from '../atoms/CustomButton';
import { NewButton } from '../atoms/NewButton';
import { useFamily } from '../hooks/useFamily';
import { usePinned } from '../hooks/usePinned';
import { Header } from '../molecules/Header';
import { FamilyTree } from '../organisms/FamilyTree';

type PinButtonProps = {
  checked?: boolean;
};

const PinButton = styled(IconButton)<PinButtonProps>`
  && {
    margin-left: 1rem;
  }

  ${(props) =>
    props.checked &&
    css`
      && {
        background: ${customColor[600]};
        color: ${customColor[50]};
        &:hover {
          background: ${customColor[600]};
          color: ${customColor[50]};
        }
      }
    `}
`;

export function Family() {
  const {
    family,
    loading,
    openModal: isOpened,
    setOpenModal: setIsOpened,
  } = useFamily();
  const { pinned, togglePinned } = usePinned();
  const isPinned = pinned.some((pin) => family._id === pin.id);
  const setCurrentMemberId = useTabStore((state) => state.setCurrentMemberId);
  const setCurrentMarriageId = useTabStore(
    (state) => state.setCurrentMarriageId
  );
  const setCurrentId = useTabStore((state) => state.setCurrentId);
  const setMode = useTabStore((state) => state.setMode);

  const handleNewMember = () => {
    setIsOpened?.(true);
    setCurrentMemberId(null);
    setCurrentMarriageId([]);
    setCurrentId('1');
    setMode(TreeDialogMode.NEW);
  };

  const navigate = useNavigate();

  return (
    <>
      <Helmet title='Families' />
      <Header
        actionButton={
          <>
            <CustomButton
              color='secondary'
              disabled={!family.hasApplicant}
              onClick={() => navigate('/reports/' + family?._id)}
              size='large'
              startIcon={<ArticleIcon />}
              sx={{ marginRight: '1rem' }}
              type='submit'
              variant='outlined'
            >
              Report
            </CustomButton>
            <NewButton fullWidth={false} onClick={handleNewMember}>
              New Member
            </NewButton>
          </>
        }
      >
        <>
          {`Family | ${family.familyName ?? ''}`}
          <PinButton
            checked={isPinned}
            onClick={() =>
              togglePinned({ id: family._id, label: family.familyName })
            }
          >
            <PushPinIcon />
          </PinButton>
        </>
      </Header>
      {loading && <LinearProgress color='secondary' sx={{ height: '1px' }} />}
      <FamilyTree
        onClose={() => setIsOpened?.(false)}
        open={isOpened ?? false}
      />
    </>
  );
}
