export enum FamilyState {
  CREATED = 'CREATED',
  ON_PROGRESS = 'ON_PROGRESS',
  FINISHED = 'FINISHED',
}

export enum FamilyStateLabel {
  CREATED = 'CREATED',
  ON_PROGRESS = 'ON PROGRESS',
  FINISHED = 'FINISHED',
}

export enum FamilyMemberStatus {
  INCOMPLETE = 'INCOMPLETE',
  ON_PROGRESS = 'ON_PROGRESS',
  NOT_FROM_LINEAGE = 'NOT_FROM_LINEAGE',
  FINISHED = 'FINISHED',
  ERRORS = 'ERRORS',
  COMPLETED_WITH_ERRORS = 'COMPLETED_WITH_ERRORS',
  COMPLETED_WITH_NO_ERRORS = 'COMPLETED_WITH_NO_ERRORS',
}

export enum QRCodeState {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
}
