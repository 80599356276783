import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Typography } from '@mui/material';
import EarningCard from 'dashboard-src/views/dashboard/EarningCard';
import TotalOrderLineChartCard from 'dashboard-src/views/dashboard/TotalOrderLineChartCard';
import TotalIncomeDarkCard from 'dashboard-src/views/dashboard/TotalIncomeDarkCard';
import TotalIncomeLightCard from 'dashboard-src/views/dashboard/TotalIncomeLightCard';
/* import PopularCard from 'dashboard-src/views/dashboard/PopularCard';
import TotalGrowthBarChart from 'dashboard-src/views/dashboard/TotalGrowthBarChart';
import TotalIncomeDarkCard from 'dashboard-src/views/dashboard/TotalIncomeDarkCard';
import TotalIncomeLightCard from 'dashboard-src/views/dashboard/TotalIncomeLightCard';
import TotalOrderLineChartCard from 'dashboard-src/views/dashboard/TotalOrderLineChartCard'; */
import StoreIcon from '@mui/icons-material/Store';
import PopularCard from 'dashboard-src/views/dashboard/PopularCard';
import TotalGrowthBarChart from 'dashboard-src/views/dashboard/TotalGrowthBarChart';
import { useQuery } from '@tanstack/react-query';
import { Api } from 'api/Api';

const gridSpacing = 3;

export function Dashboard() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const queryKey = ['dashboard', 'info'];
  const api = new Api();
  //const config = new Configuration();

  /* eslint-disable */
  const {
    isLoading: loading,
    isFetched: initialized,
    data,
    refetch: fetchData,
  } = useQuery<any>({
    queryKey,
    queryFn: async () => {
      const response = await api.get(
        `dashboard`
      );

      return response.data as any;
    },
  });
  

  return (
    <>
      <Helmet title='Dashboard Page' />
      <Typography variant='h1'>Dashboard Info</Typography>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <EarningCard isLoading={isLoading} totalFamilies={data?.totalFamilies} />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <TotalOrderLineChartCard isLoading={isLoading} totalDocuments={data?.totalDocuments} />
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} md={6} sm={6} xs={12}>
                  <TotalIncomeDarkCard isLoading={isLoading} />
                </Grid>
                <Grid item lg={12} md={6} sm={6} xs={12}>
                  <TotalIncomeLightCard
                    {...{
                      isLoading: isLoading,
                      total: 203,
                      label: 'Total Income',
                      icon: <StoreIcon fontSize='inherit' />,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item md={8} xs={12}>
              <TotalGrowthBarChart isLoading={isLoading} />
            </Grid>
            <Grid item md={4} xs={12}>
              <PopularCard isLoading={isLoading} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
