import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import styled from 'styled-components';

const Container = styled(Grid)<{ enabled: boolean }>`
  pointer-events: ${(props) => (props.enabled ? 'all' : 'none')};
  opacity: ${(props) => (props.enabled ? 1 : 0.5)};
`;

export function Gender({
  control,
  enabled = true,
}: {
  enabled?: boolean;
  control: Control<{ gender: string; type: string; parents: string }>;
}) {
  return (
    <>
      <Container enabled={enabled} item xs={6}>
        <FormLabel>Gender</FormLabel>
        <Controller
          control={control}
          name='gender'
          render={({ field }) => (
            <RadioGroup {...field}>
              <Grid container>
                <Grid item>
                  <FormControlLabel
                    control={<Radio />}
                    label='Female'
                    value='female'
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={<Radio />}
                    label='Male'
                    value='male'
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          )}
          rules={{ required: 'You must select a gender' }}
        />
      </Container>
    </>
  );
}
