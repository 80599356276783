import { Box, FormLabel, Grid, Typography } from '@mui/material';
import DownloadPdfDialog from 'components/atoms/DownloadPdfDialog';
import UploadBoxWithComponent from 'components/atoms/UploadBoxWithComponent';
import { useEffect, useMemo, useRef, useState } from 'react';
import jsQR from 'jsqr';
import { Api } from '../../api/Api';
import Configuration from '../../config/Configuration';
import { AxiosResponse } from 'axios';
import { NewButton } from 'components/atoms/NewButton';
import DeletableImage from 'components/atoms/DeletableImage';
import { statusColor } from 'theme/variants';
import { QRCodeState } from 'types/State';
import { CustomTextField } from 'components/atoms/CustomTextField';
import { styled } from 'styled-components';

const api = new Api();
const config = new Configuration();

const APOSTIL_URL = 'https://apostil.org.br/v';

const Legend = styled.span`
  height: 1rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  margin-right: 0.5rem;
  &.error {
    background-color: ${statusColor[QRCodeState.ERROR]};
  }
  &.warning {
    background-color: ${statusColor[QRCodeState.WARNING]};
  }
  &.success {
    background-color: ${statusColor[QRCodeState.SUCCESS]};
  }
`;

export const Qrcode = () => {
  const [filesData, setFilesData] = useState<
    {
      base64: string;
      duplicated: boolean;
      fileName: string;
      index: number;
      objectUrl: string;
    }[]
  >([]);
  const [newDecodedQRCodes, setNewDecodedQRCodes] = useState<
    {
      base64: string;
      error?: string;
      data?: {
        code: string;
        crc: string;
      };
    }[]
  >([]);
  const [decodedQRCodes, setDecodedQRCodes] = useState<{
    [key: string]: {
      error?: string;
      data?: {
        code: string;
        crc: string;
      };
    };
  }>({});
  const [inputedQRCodesData, setInputedQRCodesData] = useState<{
    [key: string]: {
      code: string;
      crc: string;
    };
  }>({});
  const [pdfURL, setPdfURL] = useState('');
  const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleFiles = async (_files: File[]) => {
    const newFilesData = [...filesData];

    await Promise.all(
      _files.map((file) => {
        const promise = new Promise((resolve) => {
          const reader = new FileReader();

          reader.readAsDataURL(file);
          reader.onload = async function (event) {
            const fileBase64 = (
              event.target?.result ? event.target?.result : ''
            ) as string;

            if (!fileBase64) {
              resolve(null);

              return;
            }

            const fileObjectUrl = URL.createObjectURL(file);
            const duplicated = newFilesData.reverse().find((oldFileData) => {
              if (oldFileData.base64 === fileBase64) {
                oldFileData.duplicated = true;

                return true;
              }

              return false;
            });

            const index = duplicated ? duplicated.index : 0;

            newFilesData.push({
              base64: fileBase64,
              duplicated: duplicated ? true : false,
              fileName: `${file.name}${index ? `-${index}` : ''}`,
              index: index,
              objectUrl: fileObjectUrl,
            });

            resolve(null);
          };
        });

        return promise;
      })
    );

    setFilesData(newFilesData);
    decodeQRCodes({ filesData: newFilesData });
  };

  const decodeQRCodes = async ({
    filesData,
  }: {
    filesData: {
      base64: string;
      duplicated: boolean;
      fileName: string;
      index: number;
      objectUrl: string;
    }[];
  }) => {
    const bases64ToDecode: {
      [key: string]: boolean;
    } = {};

    for (const fileData of filesData) {
      if (!decodedQRCodes[fileData.base64]) {
        bases64ToDecode[fileData.base64] = true;
      }
    }
    const newDecodedQRCodes = await Promise.all(
      Object.keys(bases64ToDecode).map(async (base64ToDecode) => {
        const code = await getCode(base64ToDecode);

        if (code && code.data.includes(APOSTIL_URL)) {
          const url = new URL(code.data);
          const searchParamsStr = url.searchParams;
          const dataCode = searchParamsStr.get('number');
          const crc = searchParamsStr.get('crc');

          if (!dataCode || !crc) {
            return {
              base64: base64ToDecode,
              error: 'Data not recognized',
            };
          }

          return {
            base64: base64ToDecode,
            data: {
              code: dataCode,
              crc,
            },
          };
        } else
          return {
            base64: base64ToDecode,
            error: 'Data not recognized',
          };
      })
    );

    setNewDecodedQRCodes(newDecodedQRCodes);
  };

  useEffect(() => {
    if (!newDecodedQRCodes.length) return;
    const newStateDecodedQRCodes = { ...decodedQRCodes };

    newDecodedQRCodes.forEach((newDecodedQRCode) => {
      newStateDecodedQRCodes[newDecodedQRCode.base64] = newDecodedQRCode;
    });
    setNewDecodedQRCodes([]);
    setDecodedQRCodes(newStateDecodedQRCodes);
  }, [decodedQRCodes, newDecodedQRCodes]);

  const handleValidate = async () => {
    setIsDownloadDialogOpen(true);
    const urls: string[] = [];

    Object.entries(decodedQRCodes).forEach(([key, decodedQRCode]) => {
      if (decodedQRCode.data) {
        urls.push(
          `${APOSTIL_URL}?number=${decodedQRCode.data.code}&crc=${decodedQRCode.data.crc}`
        );
      } else if (
        inputedQRCodesData[key]?.code &&
        inputedQRCodesData[key]?.crc
      ) {
        urls.push(
          `${APOSTIL_URL}?number=${inputedQRCodesData[key]?.code}&crc=${inputedQRCodesData[key]?.crc}`
        );
      }
    });

    const response: AxiosResponse<Blob | MediaSource> = await api.post(
      config.endpoint.post.validateQRCodes,
      {
        urls,
      },
      { responseType: 'blob' }
    );
    const pdfURL = URL.createObjectURL(response.data);

    setPdfURL(pdfURL);
  };

  const onCloseDialog = () => {
    setPdfURL('');
    setIsDownloadDialogOpen(false);
  };

  const handleDelete = (imageUrl: string) => {
    setFilesData((prevFilesData) => {
      let fileToDeleteIndex: number | undefined;
      const newFilesData = prevFilesData.filter((fileData, index) => {
        if (fileData.objectUrl === imageUrl) {
          fileToDeleteIndex = index;

          return false;
        }

        return true;
      });

      if (fileToDeleteIndex === undefined) return prevFilesData;

      URL.revokeObjectURL(prevFilesData[fileToDeleteIndex].objectUrl);

      const duplicatedIndexes: number[] = [];

      newFilesData.find((fileData, index) => {
        if (
          fileToDeleteIndex !== undefined &&
          fileData.base64 === prevFilesData[fileToDeleteIndex].base64
        ) {
          duplicatedIndexes.push(index);

          return duplicatedIndexes.length === 2;
        }
      });

      if (duplicatedIndexes.length === 1) {
        newFilesData[duplicatedIndexes[0]].duplicated = false;
      }

      return newFilesData;
    });
  };

  const disabled = useMemo(
    () => !filesData.find((fileData) => decodedQRCodes[fileData.base64]?.data),
    [decodedQRCodes, filesData]
  );

  // const hasErrors = useMemo(
  //   () => filesData.find((fileData) => decodedQRCodes[fileData.base64]?.error),
  //   [decodedQRCodes, filesData]
  // );

  // const hasWarnings = useMemo(
  //   () => filesData.find((fileData) => fileData.duplicated),
  //   [filesData]
  // );

  const valueChangeHandler = ({
    base64,
    key,
    value,
  }: {
    base64: string;
    key: 'code' | 'crc';
    value: string;
  }) => {
    const newInputedQRCodesData = {
      ...inputedQRCodesData,
    };

    if (!newInputedQRCodesData[base64]) {
      newInputedQRCodesData[base64] = {
        code: '',
        crc: '',
      };
    }
    newInputedQRCodesData[base64][key] = value.toUpperCase();
    setInputedQRCodesData(newInputedQRCodesData);
  };

  return (
    <div>
      <DownloadPdfDialog
        handleClose={onCloseDialog}
        isOpen={isDownloadDialogOpen}
        pdfURL={pdfURL}
      />
      <Box mt={5} px={4}>
        <Grid container>
          <Grid item xs={12}>
            <Box my={5}>
              <FormLabel>Documents</FormLabel>
            </Box>
            <Box my={5}>
              <UploadBoxWithComponent
                emptyAditionalLabel='to decode the QRCodes'
                onDrop={handleFiles}
              >
                {filesData.length &&
                  filesData.map((fileData, index) => (
                    <Box
                      height='100%'
                      key={fileData.objectUrl}
                      mr={index !== filesData.length - 1 ? '1rem' : 0}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <DeletableImage
                        error={
                          decodedQRCodes[fileData.base64]?.error &&
                          (inputedQRCodesData[fileData.base64]?.code?.length !==
                            10 ||
                            inputedQRCodesData[fileData.base64]?.code?.split(
                              '-'
                            )[0].length !== 7 ||
                            inputedQRCodesData[fileData.base64]?.code?.split(
                              '-'
                            )[1].length !== 2 ||
                            inputedQRCodesData[fileData.base64]?.crc?.length !==
                              8)
                            ? true
                            : false
                        }
                        handleDelete={handleDelete}
                        imageUrl={fileData.objectUrl}
                        loading={
                          !decodedQRCodes[fileData.base64]?.error &&
                          !decodedQRCodes[fileData.base64]?.data
                        }
                        warning={
                          fileData.duplicated ? 'Duplicated image' : undefined
                        }
                      />
                      <Box mt={2}>
                        <CustomTextField
                          disabled={
                            decodedQRCodes[fileData.base64]?.data ? true : false
                          }
                          fullWidth
                          onChange={(e) => {
                            valueChangeHandler({
                              base64: fileData.base64,
                              key: 'code',
                              value: e.target.value,
                            });
                          }}
                          placeholder='Código'
                          size='small'
                          value={
                            decodedQRCodes[fileData.base64]?.data?.code
                              ? decodedQRCodes[fileData.base64]?.data?.code
                              : inputedQRCodesData[fileData.base64]?.code
                                ? inputedQRCodesData[fileData.base64]?.code
                                : ''
                          }
                        />
                      </Box>
                      <Box mt={2}>
                        <CustomTextField
                          disabled={
                            decodedQRCodes[fileData.base64]?.data ? true : false
                          }
                          fullWidth
                          onChange={(e) => {
                            valueChangeHandler({
                              base64: fileData.base64,
                              key: 'crc',
                              value: e.target.value,
                            });
                          }}
                          placeholder='CRC'
                          size='small'
                          value={
                            decodedQRCodes[fileData.base64]?.data?.crc
                              ? decodedQRCodes[fileData.base64]?.data?.crc
                              : inputedQRCodesData[fileData.base64]?.crc
                                ? inputedQRCodesData[fileData.base64]?.crc
                                : ''
                          }
                        />
                      </Box>
                    </Box>
                  ))}
              </UploadBoxWithComponent>
            </Box>
            <Box display='flex' flexDirection='column'>
              <Box display='flex'>
                <Legend className='error' />
                <Typography mb={2}>
                  {`Couldn't read some of your QRcodes, please input the code and the crc or we won't validate those`}
                </Typography>
              </Box>
              <Box display='flex'>
                <Legend className='warning' />
                <Typography mb={2}>{`You have duplicated images`}</Typography>
              </Box>
              <Box display='flex'>
                <Legend className='success' />
                <Typography>{`QRCode successfully decoded`}</Typography>
              </Box>
            </Box>
            <Grid container mx={0} my={5} spacing={6} width='100%'>
              <Box>
                <NewButton
                  disabled={disabled ? true : false}
                  fullWidth={false}
                  onClick={handleValidate}
                >
                  Validate and download PDF
                </NewButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

const getCode = async (image: string): Promise<{ data: string } | null> => {
  return new Promise((resolve) => {
    const img = new Image();

    img.src = image;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      if (!context) return;
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);

      const imageData = context.getImageData(0, 0, img.width, img.height);

      const code = jsQR(imageData.data, img.width, img.height);

      resolve(code);
    };
  });
};
